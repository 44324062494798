.sidebar-link {
  text-decoration: none;
  list-style: none;
  height: 60px;
  color: $c3-text-grey;
  font-size: $fz-16;
  font-weight: $fw-6;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;

  &:hover {
    background-color: $secondary;
    color: $cl-text-whiteF;
  }

  &.active {
    background-color: $primary;
    color: $cl-text-whiteF;
  }
}

.submenu {
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  list-style: none;
  display: inline-block;
  width: 100%;
  height: 60px;
  color: $c3-text-grey;
  font-size: $fz-16;
  font-weight: $fw-6;
  color: $c3-text-grey;
  background-color: $gray-300;
  cursor: pointer;
  padding: 5px 0;

  &:hover {
    background-color: $secondary;
    color: $cl-text-whiteF;
  }

  &.active {
    background-color: $subitem-active;
    color: $cl-text-whiteF;
  }

  .sub-item {
    display: inline-block;
    padding: 5px 0;
    margin-left: 3rem;
  }
}
