@mixin button-1 {
  width: 175px;
  height: 64px;
  left: 0px;
  top: 305px;
  border: none;
  background: #e1b067;
}
@mixin text-header {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: black;
}

@mixin button {
  width: 100%;
  background: #e1b067;
  border-radius: 0;
  padding: 18px 40px;
  font-size: 24px;
  font-weight: 300;
}

@mixin button-2 {
  width: 100%;
  text-align: center;
  background-color: #dedddc;
  padding: 18px 40px;
  font-size: 24px;
  font-weight: 600;
}
// tableCart
@mixin button-cart($bg-color) {
  background: $bg-color;
  border-radius: 4px;
  color: #fff;
  transition: all 0.3s ease;
  border: none;
  &:hover {
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14),
      0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(-0.25em);
  }
}

@mixin shadow {
  box-shadow: 0 0 10px #0000001a;
}

@mixin primary-color($prop-name) {
  #{$prop-name}: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #6181f3),
    color-stop(99.48%, #7c97f5)
  ); /* Chrome, Safari4+ */
  #{$prop-name}: -webkit-linear-gradient(
    180deg,
    #6181f3 0%,
    #7c97f5 99.48%
  ); /* Chrome10+, Safari5.1+ */
  #{$prop-name}: -moz-linear-gradient(
    180deg,
    #6181f3 0%,
    #7c97f5 99.48%
  ); /* FF3.6+ */
  #{$prop-name}: linear-gradient(180deg, #6181f3 0%, #7c97f5 99.48%);
}

@mixin svg-icon-color($color) {
  svg {
    g {
      [fill] {
        transition: fill 0.3s ease;
        fill: $color;
      }
    }

    &:hover {
      g {
        [fill] {
          transition: fill 0.3s ease;
        }
      }
    }
  }
}

@mixin svg-icon-transition() {
  svg {
    g {
      [fill] {
        transition: fill 0.3s ease;
      }
    }
  }
}

@mixin svg-icon-size($size) {
  svg {
    height: $size;
    width: $size;
  }
}

@mixin svg-logo-color($color) {
  svg {
    path {
      fill: $color;
    }
  }
}

// font-weight
$fw-2: 200;
$fw-3: 300;
$fw-4: 400;
$fw-5: 500;
$fw-6: 600;

//font-size:
$fz-12: 12px;
$fz-13: 13px;
$fz-14: 14px;
$fz-16: 16px;
$fz-18: 18px;
$fz-20: 20px;
$fz-22: 22px;
$fz-24: 24px;
$fz-30: 30px;
$fz-32: 32px;
$fz-40: 40px;

//color text
$cl-text-dark: #000000;
$cl-text-black: #140101;
$cl-text-black-2: #1e1d1d;
$cl-text-black-3: #212529;
$cl-text-white: #f7f7f7;
$cl-text-grey: #cbc9c9;
$c2-text-grey: #757575;
$c3-text-grey: #6f6f6f;
$c4-text-grey: #808191;
$c5-text-grey: #ababab;
$cl-text-whiteF: #ffffff;
$cl-text-whiteFC: #fffcfc;
$cl-text-whiteF8: #fff8f8;
$cl-text-green: #1ed90e;
$cl-text-purple: #dd2aed;
$c1-text-blue: #3d5ee1;
$cl-text-red: #fc0303;
$cl-text-red-1: #f50e0e;

// override primary bootstrap
$primary: #9e2103;
$secondary: #ec8702;
$error: #fc0303;
$success: #1ed90e;
$subitem-active: #901f03;

//background
$bg-white: #ffffff;
$bg-grey: #eef0f8;
$bg-grey-C: #cccccc;
$bg-grey-2: rgba(33, 33, 33, 0.08);
$bg-grey-3: #f7f7fa;
$bg-grey-4: #e8e8e833;
$bg-grey-5: #e9ecef;
$bg-yellow-1: #f8b653;
$bg-yellow-2: #e1b067;
$bg-purple: #6200ee;
$bg-light-blue-1: #1877f2;
$bg-light-blue-2: #0d6efd;
$bg-light-blue-3: #18aefa;
$bg-blue-primary: linear-gradient(180deg, #6181f3 0%, #7c97f5 99.48%);
$bg-purple-primary: linear-gradient(
  270deg,
  rgba(62, 32, 248, 0.9) 5.14%,
  #d017ee 89.71%
);
$bg-hazel-1: linear-gradient(90deg, #9e2103 0%, #ed8603 50%, #fcb045 100%);

// Gray colors
$gray-50: #f8f9fa;
$gray-100: #f3f6f9;
$gray-200: #ebedf3;
$gray-300: #e4e6ef;
$gray-400: #d1d3e0;
$gray-500: #b5b5c3;
$gray-600: #7e8299;
$gray-700: #5e6278;
$gray-800: #3f4254;
$gray-900: #181c32;

// Dark colors
$dark: $gray-900;
$dark-75: $gray-800;
$dark-65: $gray-700;
$dark-50: $gray-600;
$dark-25: $gray-400;

// Text muted
$text-muted: $gray-500;

// Border
$border-radius: 0.42rem;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12;
$grid-gutter-width: 25px;
$grid-divider-bg: $gray-200;

// Transition
$transition: all 0.15s ease;
$transition-link: color 0.15s ease, background-color 0.15s ease,
  border-color 0.15s ease, box-shadow 0.15s ease;
$transition-input: color 0.15s ease, background-color 0.15s ease,
  border-color 0.15s ease, box-shadow 0.15s ease;

// Test
$test-nav-height: calc(100vh - 72px - 60px - 70px);
$test-content-height: calc(100vh - 72px - 60px - 70px - 56px);
$test-listening-content-height: calc(100vh - 72px - 60px - 70px - 56px - 56px);
