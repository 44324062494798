.logo {
  height: 40px;
  display: flex;

  img {
    width: 40px;
  }

  h1 {
    color: $primary;
  }
}
