.loading-indicator {
  width: 300px;
  height: 300px;
}

.btn .loading-indicator {
  width: 25px;
  height: 25px;
  scale: 3;
}
