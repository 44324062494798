form {
  .form-group {
    margin-bottom: 20px;

    .form-group-input {
      position: relative;

      label {
        font-size: $fz-12;
        font-weight: $fw-5;
        color: $c5-text-grey;
        position: absolute;
        top: -10px;
        left: 10px;
        background: $bg-white;
        margin-bottom: 0;
        padding: 0 5px;
        span {
          color: $cl-text-red;
          display: inline-block;
          margin-left: 3px;
        }
      }

      .invalid-error-custom {
        color: $cl-text-red;
        font-size: $fz-14;
      }
    }
  }

  .form-action {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}
