.login-form {
  height: 100%;

  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    .row {
      border-radius: 8px;
      @include shadow();

      .col-left {
        width: 400px;
        padding: 0;
        justify-content: end;
        align-items: center;
        position: relative;
        background: rgba(158, 33, 3, 1);
        border-radius: 8px 20px 20px 8px;
        &::before {
          content: "";
          background-image: url(../../../img/login-icon-01.bc954fc19981317d.png);
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          left: 0;
          width: 144px;
          height: 144px;
          transform: translate(-20%, -20%);
        }
        img {
          width: 100%;
          height: 100%;
        }
        &::after {
          content: "";
          background-image: url(../../../img/login-icon-02.0ca847cf477a2002.png);
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          right: 0;
          width: 144px;
          height: 144px;
          transform: translate(10%, -10%);
        }
      }
      .col-right {
        width: 400px;
        align-items: center;
        justify-content: center;
        padding: 40px;
        .item {
          width: 100%;
          h1 {
            font-size: $fz-22;
            font-weight: bold;
            margin-bottom: 5px;
            text-align: left;
            color: $cl-text-dark;
          }
          p {
            font-size: $fz-14;
            color: $c4-text-grey;
            font-weight: $fw-5;
            margin-bottom: 30px;
            a {
              margin-left: 5px;
            }
          }
          h2 {
            font-size: $fz-20;
            margin-bottom: 2rem;
          }
          form {
            .form-group {
              .forgot-password {
                text-decoration: none;
                font-size: $fz-14;
              }

              button {
                padding: 6px 12px;
              }
            }
          }
        }
      }
    }
  }
}
