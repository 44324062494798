.col-lg-12 {
  max-height: $test-content-height;
  .col-left {
    overflow: auto;
    width: 50%;
    background-color: $bg-grey-5;
    padding: 1rem;
    margin-right: 0.75rem;
  }
  .col-right {
    overflow: auto;
    width: 50%;
    padding: 1rem;
    .option {
      p {
        padding-left: 12px;
      }
      .form-check {
        padding-left: 12px;
      }
    }
  }
}
