.profile-info{
    .row{
        .col-left{
            width: 30%;
            border-right: 1px solid lightgray;
            .item{
                justify-content: space-evenly;
                align-items: center;
                img{
                    width: 100px;
                    border-radius: 100%;
                }
            }
        }
        .col-right{
            width: 70%;
        }
    }
}