.tab-content{
  .tab-pane{
    .general-info{
      span{
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
}

.top{
  li{
    list-style: none;
    margin-bottom: 0.5rem;
  }
}

.bottom{
  li{
    margin-bottom: 0.5rem;
    span{
      margin-right: 5px;
      font-weight: $fw-5;
    }
  }
}