$card-height: calc(100vh - 72px);

.student-course {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 100%;
  justify-content: center;

  .student-course-lesson.card {
    height: $card-height;
    border-radius: 0;
    flex-grow: 1;

    .card-body {
      overflow: auto;

      .listening-audio {
        width: 100%;
        margin-bottom: 1rem;
      }

      .lesson-tab {
        padding-top: 1rem;
      }
    }
  }

  .student-course-list.card {
    height: $card-height;
    overflow: auto;
    min-width: 250px;
    width: 250px;
    border-radius: 0;

    .card-body {
      padding: 0;
      overflow: auto;

      .lesson-item {
        cursor: pointer;
        padding: 1rem;

        &:hover {
          background-color: $secondary;
          color: $cl-text-whiteF;
        }

        &.active {
          background-color: $primary;
          color: $cl-text-whiteF;
        }
      }

      .lesson-subitem {
        cursor: pointer;
        padding: 0.5rem 1rem 0.5rem 2rem;

        &:hover {
          background-color: $secondary;
          color: $cl-text-whiteF;
        }

        &.active {
          background-color: $subitem-active;
          color: $cl-text-whiteF;
        }
      }
    }
  }
}
