.test-wrapper {
  .col-lg-10 {
    .question-wrapper {
      .form-group.row {
        margin-bottom: 0;

        .col-lg-12 {
          padding: 0;
          max-height: $test-content-height;

          .context-content {
            img {
              width: 40%;
              margin-bottom: 1rem;
            }
          }
          .question-container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin-bottom: 1rem;

            .question-list {
              margin-top: 1rem;
              .question-number {
                display: inline-block;
                p {
                  margin-top: 1rem;
                }
                .vocab-list {
                  li {
                    list-style: inside;
                  }
                }
                .examples {
                  margin-top: 2rem;
                  .nav-link {
                    background-color: $secondary;
                    font-weight: 500;
                  }
                }
              }
            }

            .question-content {
              display: flex;

              .question-number {
                display: inline-block;
                width: 40px;
                height: 40px;
                text-align: center;
                border-radius: 100%;
                background-color: rgba(173, 216, 230, 0.3);
                line-height: 40px;
                flex: 0 0 auto;
              }

              .question {
                margin-left: 0.5rem;
                margin-top: 0.5rem;
              }
            }

            .option {
              p {
                padding-left: 24px;
              }
              .form-check {
                input {
                  margin-right: 10px;
                }
              }
            }

            .mt-2 {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .col-lg-2 {
    max-height: $test-nav-height;
    overflow: auto;
    padding: 12px;
    .test-navigation {
      .test-question-list {
        .test-question-list-part {
          margin-bottom: 1rem;
          h6 {
            font-weight: 700;
          }
          .test-question-list-wrapper {
            flex-wrap: wrap;
            .list-item {
              display: inline-block;
              border-radius: 3px;
              border: 1px solid black;
              font-weight: 600;
              margin-right: 4px;
              margin-bottom: 8px;
              width: 30px;
              height: 25px;
              font-size: 11px;
              line-height: 25px;
              text-align: center;
              cursor: pointer;

              &.list-item-answered {
                background-color: $success;
                color: $cl-text-whiteF;
              }

              &.list-item-right-answer {
                background-color: $success;
                color: $cl-text-whiteF;
              }

              &.list-item-wrong-answer {
                background-color: $error;
                color: $cl-text-whiteF;
              }

              &:hover {
                background-color: $bg-light-blue-1;
                color: $cl-text-whiteF;
              }
            }
          }
        }
      }
    }
  }
}

.section-content {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .section-header {
    h6 {
      color: $primary;
    }

    p {
      margin: 0;
    }
  }
}

.right-answer {
  background-color: $success;
  border-radius: 4px;
}

.wrong-answer {
  background-color: $error;
  border-radius: 4px;
}

.primary-header {
  color: $primary;
}
