.listening-test-wrapper {
  display: flex;
  flex-direction: row;

  .audio-listening {
    width: 100%;
    padding-right: 1rem;
    margin-bottom: 8px;
  }

  .sections-wrapper {
    max-height: $test-content-height;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    $multiple-section-height: calc(100vh - 72px - 60px - 70px - 56px - 56px);
    &.sections-wrapper-multiple-section {
      max-height: $multiple-section-height;

      .section-wrapper {
        max-height: $multiple-section-height;
      }
    }

    .section-wrapper {
      max-height: calc(100vh - 72px - 60px - 70px - 48px);
    }

    .section-wrapper,
    .section-multiple-wrapper {
      display: flex;
      flex-direction: row;
      flex: 0 0 auto;
      overflow: hidden;

      .col-left {
        background-color: $bg-grey-5;
        overflow: auto;
        padding: 1rem;
        width: 50%;
      }

      .col-right {
        overflow: auto;
        padding: 1rem;
        width: 50%;
      }

      .listening-multiple-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: auto;
        --bs-gutter-x: 25px;
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));
        padding: 1rem calc(1rem + 0.5 * var(--bs-gutter-x));
      }

      .question-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;

        .question-content {
          display: flex;

          .question-number {
            display: inline-block;
            width: 40px;
            height: 40px;
            text-align: center;
            border-radius: 100%;
            background-color: rgba(173, 216, 230, 0.3);
            line-height: 40px;
            flex: 0 0 auto;
          }

          .question {
            margin-left: 0.5rem;
            display: flex;
            align-items: center;
          }
        }
        .option {
          p {
            padding-left: 24px;
          }
          .form-check {
            input {
              margin-right: 10px;
            }
          }
        }

        .mt-2 {
          font-weight: 500;
        }
      }
    }
  }

  .col-lg-2 {
    max-height: $test-nav-height;
    overflow: auto;
    padding: 12px;

    .test-question-list {
      .test-question-list-part {
        margin-bottom: 1rem;

        h6 {
          font-weight: 700;
        }

        .test-question-list-wrapper {
          flex-wrap: wrap;

          .list-item {
            display: inline-block;
            border-radius: 3px;
            border: 1px solid black;
            font-weight: 600;
            margin-right: 4px;
            margin-bottom: 8px;
            width: 30px;
            height: 25px;
            font-size: 11px;
            line-height: 25px;
            text-align: center;
            cursor: pointer;

            &.list-item-right-answer {
              background-color: $success;
              color: $cl-text-whiteF;
            }

            &.list-item-wrong-answer {
              background-color: $error;
              color: $cl-text-whiteF;
            }

            &:hover {
              background-color: $bg-light-blue-1;
              color: $cl-text-whiteF;
            }
          }
        }
      }
    }
  }
}
