.question-group {
  border: 1px solid $card-border-color;
  position: relative;
  padding: 28px 12px 16px;
  margin-bottom: 2rem;

  .question-group-header {
    font-size: $fz-16;
    font-weight: $fw-5;
    color: $cl-text-black;
    font-weight: 600;
    position: absolute;
    top: -12px;
    left: 10px;
    background: $bg-white;
    margin-bottom: 0;
    padding: 0 5px;
  }
}
