.junior-test-wrapper {
  .audio-listening {
    width: 100%;
    padding-right: 1rem;
    margin-bottom: 8px;
  }

  .col-lg-10 {
    .question-wrapper {
      .form-group.row {
        .col-lg-12 {
          max-height: $test-content-height;

          &.listening {
            max-height: $test-listening-content-height;
          }
        }
      }
    }
  }
}
