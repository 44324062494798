@import "./base/base";
@import "./variables/variables";

@import "bootstrap/scss/bootstrap.scss";

// Pages
@import "./pages/NotFound/notfound";
@import "./pages/Login/login";
@import "./pages/Profile/profile";
@import "./pages/StudentExamination/studentExaminations";
@import "./pages/StudentExamination/doingExamination";
@import "./pages/StudentPracticeTest/ielts";
@import "./pages/StudentPracticeTest/IeltsListening/listeningInfoPage";
@import "./pages/StudentCourse/studentCoursePage";

// Components
@import "./components/Layout/Header/header";
@import "./components/Layout/Subheader/subheader";
@import "./components/Layout/Sidebar/sidebar";
@import "./components/Layout/Sidebar/submenu";
@import "./components/Layout/Footer/footer";
@import "./components/Layout/layout";
@import "./components/MasterDetail/master-detail";
@import "./components/Card/card";
@import "./components/Table/table";
@import "./components/Form/form";
@import "./components/LoadingIndicator/loading-indicator";
@import "./components/Logo/logo";
@import "./components/ExaminationPartialForm/examination-partial-form";
@import "./components/Player/player";
@import "./components/IeltsParts/listeningParts";
@import "./components/IeltsParts/readingParts";
@import "./components/IeltsParts/ielts";
@import "./components/PracticeTestParts/juniorParts";
@import "./components/Discussion/discussion";
@import "./components/StudentTest/studentTestCard";
