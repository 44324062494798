.lesson-discussions{
  .discussion-title{
    font-weight: bold;
    margin: 1rem auto;
  }
  .discussion-container.container{
    form{
      margin-bottom: 3rem;
      .student-discussion{
        .discussion-box.row{
          .avatar{
            text-align: center;
            img{
              width: 40px;
              border: solid 2px;
              border-radius: 100%;
            }
          }
        }
      }
    }
    .discussion-list{
      .sort{
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 3rem;
        span{
          width: fit-content;
          font-weight: bold;
        }
        select{
          width: 20%;
        }
      }
      .discussion-content{
        margin-bottom: 3rem;
        .left-part{
          text-align: center;
          img{
            width: 40px;
            border: solid 2px;
            border-radius: 100%;
          }
        }
        .right-part{
          .discussion-info,
          .reply-info{
            margin-bottom: 0.75rem;
            .user-name{
              display: inline-block;
              font-weight: bold;
              margin-right: 1rem;
            }
            .date-created{
              color: rgba($color: #000000, $alpha: .5);
            }
          }
          .discussion-text,
          .reply-text{
            border: solid 1px rgba($color: #000000, $alpha: .5);
            border-radius: 10px;
            padding: 1rem;
          }
          .reply-actions{
            display: flex;
            color: rgba($color: #000000, $alpha: .5);
            cursor: pointer;
            margin-top: 0.5rem;
            .action{
              &:hover{
                text-decoration: underline;
              }
            }
          }
          .replies{
            .reply-content{
              margin: 1rem 0;
            }
          }
          
        }
      }
    }
  }
}