.header {
  width: 100%;
  flex-shrink: 0;
  z-index: 10;
  background-color: $bg-white;
  @include shadow();

  .container {
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;

    .header-logo {
      text-decoration: none;
    }

    .user {
      ul {
        justify-content: space-between;
        align-items: center;
        li {
          cursor: pointer;
          i {
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 100%;
            background-color: $bg-grey-3;
            &:hover {
              background-color: $bg-grey-4;
            }
          }
          .user-info {
            justify-content: space-between;
            align-items: center;
            &:hover {
              background-color: $bg-grey-4;
            }
            .avatar {
              img {
                width: 40px;
                border: solid 2px;
                border-radius: 100%;
              }
            }
            .login-info {
              h6 {
                font-size: $fz-14;
                font-weight: $fw-5;
                color: $cl-text-black-2;
                margin-bottom: 3px;
              }
              p {
                font-size: $fz-12;
                font-weight: $fw-5;
                color: $c1-text-blue;
                margin-bottom: 0;
              }
            }
          }
          .dropdown-menu {
            border-radius: 0;
            box-shadow: 0px 5px 10px $bg-grey;
            .user-info {
              padding: 10px 15px;
              img {
                border: none;
              }
            }
            .login-info {
              h6 {
                font-size: $fz-16;
                color: $cl-text-dark;
              }
              p {
                font-size: $fz-14;
                color: $c2-text-grey;
              }
            }
            a {
              background-color: $bg-white;
              color: $cl-text-black-3;
              font-size: $fz-14;
              font-weight: $fw-5;
              display: inline-block;
              padding: 10px 15px;
              border-top: 1px solid #e3e3e3;
              &:hover {
                background-color: $bg-grey-5;
                color: $c1-text-blue;
              }
            }
          }
        }
      }
    }
  }
}

// Tablet mode
@include media-breakpoint-down(sm) {
  .header .container {
    padding: 0 0 0 60px;
  }
}
