//
// Table
//

$table-bg: transparent;
$table-accent-bg: $gray-200;
$table-hover-bg: $gray-300;
$table-active-bg: $table-hover-bg;

$table-border-color: $gray-200;
$table-dark-color: $white;
$table-dark-bg: $dark;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-color: $table-dark-color;
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($table-dark-bg, 7.5%);

$table-head-bg: $gray-100;
$table-head-color: $dark-75;
$table-head-font-size: 1rem;
$table-head-font-weight: 600;
$table-compact-head-font-size: 0.9rem;
$table-compact-head-color: $text-muted;
$table-compact-head-text-transform: uppercase;
$table-compact-head-font-weight: 600;
$table-compact-head-font-weight: 600;
$table-compact-head-letter-spacing: 0.1rem;
$table-head-height: 55px;

$pagination-height: 63px;

.react-bootstrap-table {
  height: calc(100% - #{$pagination-height} - 1rem);
  margin-bottom: 1rem;

  .table.table-head-custom thead tr {
    th {
      outline: none;

      &.sortable {
        .svg-icon-sort {
          opacity: 0;
        }

        &:hover {
          cursor: pointer;
          color: $secondary;

          .svg-icon-sort {
            opacity: 1;
          }
        }
      }

      &.sortable-active {
        color: $primary;
      }
    }
  }

  .table {
    margin-bottom: 0;

    thead {
      th,
      td {
        font-weight: $table-head-font-weight;
        font-size: $table-head-font-size;
        border-bottom-width: 1px;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    tbody {
      height: calc(100% - #{$table-head-height});
      overflow-x: scroll;
    }

    &.table-head-borderless {
      thead {
        th,
        td {
          border-top: 0;
        }
      }
    }

    &.table-head-solid {
      thead {
        th,
        td {
          background-color: $table-head-bg;
        }
      }
    }

    &.table-light {
      @each $name, $color in $theme-colors {
        &-#{$name} {
          thead {
            tr {
              color: $color;
              background-color: rgba($color, 0.1);
            }
          }
        }
      }
    }

    &.table-head-custom {
      thead {
        tr,
        th {
          font-weight: $table-compact-head-font-weight;
          color: $table-compact-head-color;
          font-size: $table-compact-head-font-size;
          text-transform: $table-compact-head-text-transform;
          letter-spacing: $table-compact-head-letter-spacing;
        }
      }
    }

    &.table-head-bg {
      thead {
        tr,
        th {
          background-color: $table-head-bg;
          border-bottom: 0;
          letter-spacing: 1px;

          &:first-child {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
          }

          &:last-child {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }
        }
      }
    }

    &.table-separate {
      th,
      td {
        border-top: 0;
        border-bottom: 1px solid $table-border-color;

        &:first-child {
          padding-left: 0 !important;
        }

        &:last-child {
          padding-right: 0 !important;
        }
      }

      tbody {
        tr:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }

    &.table-vertical-center {
      th,
      td {
        vertical-align: middle;
      }
    }
  }

  .table:not(.table-bordered) {
    thead {
      th,
      td {
        border-top: 0;
      }
    }
  }
}

.pagination {
  height: $pagination-height;
}

// Tablet mode
@include media-breakpoint-down(md) {
  $pagination-height-tablet: 120px;

  .react-bootstrap-table {
    height: calc(100% - #{$pagination-height-tablet} - 1rem);
  }

  .pagination {
    height: $pagination-height-tablet;
  }
}

.unformat-bootstrap-table {
  table {
    border-collapse: inherit !important;

    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
      border: 1px solid !important;
    }

    td {
      padding: 0 4px !important;
    }
  }
}
