.form{
    .part-name{
        li{
            list-style: none;
            padding-left: 1.5rem;
        }
    }
    .form-group{
        .question-number{
            margin-bottom: 0.5rem;
            label{
                margin-left: 0.5rem;
                margin-right: 5rem;
            }
        }
    }
}