@import "../variables/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", "Helvetica", sans-serif;
}

.container {
  max-width: 1280px;
  padding: 0;
  margin: 0 auto;
}
.wrapper {
  padding: 0 30px;
}

.shadow {
  @include shadow();
}

.error-text {
  color: $cl-text-red;
}

.separator {
  height: 1px;
  background-color: #d1d3e0;
}

.input-danger {
  color: $cl-text-red;
  margin-left: 3px;
}
